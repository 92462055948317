import React, { Component } from "react";
import { storeProducts, detailProduct } from "./data";
const ProductContext = React.createContext();

class ProductProvider extends Component {
    state = {
        products: [],
        allProducts: [], // Preserve the unfiltered list here
        detailProduct: detailProduct,
        cart: [],
        modalOpen: false,
        modalProduct: detailProduct,
        cartSubTotal: 0,
        cartTax: 0,
        cartTotal: 0
    };
    

    componentDidMount() {
        this.setProducts();
        this.loadCartFromLocalStorage(); // Load cart from localStorage on mount
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.cart !== this.state.cart) {
            this.saveCartToLocalStorage(); // Save cart to localStorage when it changes
        }
    }

    saveCartToLocalStorage = () => {
        try {
            localStorage.setItem("cart", JSON.stringify(this.state.cart));
        } catch (error) {
            console.error("Failed to save cart to localStorage", error);
        }
    };

    loadCartFromLocalStorage = () => {
        try {
            const storedCart = localStorage.getItem("cart");
            if (storedCart) {
                this.setState({ cart: JSON.parse(storedCart) }, this.addTotals); // Update totals after loading
            }
        } catch (error) {
            console.error("Failed to load cart from localStorage", error);
        }
    };

    filterProducts = (value) => {
        if (!value) {
            // Reset to all products if no filter value
            this.setState({ products: this.state.allProducts });
            return;
        }
    
        const searchValue = value.toLowerCase();
    
        const filteredProducts = this.state.allProducts.filter(
            (item) =>
                (item.title && item.title.toLowerCase().includes(searchValue)) ||
                (item.englishTitle && item.englishTitle.toLowerCase().includes(searchValue))
        );
    
        this.setState({ products: filteredProducts });
    };
    
    
    setProducts = async () => {
        const API_URL = "https://api.garturbo.com"; 
        try {
            // Use the HTTPS URL for fetching products
            const response = await fetch(`${API_URL}/stock/getStock`);
    
            if (!response.ok) {
                throw new Error(`Error fetching products: ${response.statusText}`);
            }
    
            const rawData = await response.json();
            if (rawData.status === "success" && rawData.data && rawData.data.stocks) {
                const products = rawData.data.stocks.map((product) => ({
                    ...product,
                    img: `${API_URL}/images/${product.img}`,
                    count: 0,
                    total: 0,
                    stock: product.stock, // Include stock
                }));                
                this.validateCart(products);
                this.setState({
                    products,
                    allProducts: products // Keep a copy of the unfiltered data
                });
                
            } else {
                throw new Error("Unexpected API response structure");
            }
        } catch (error) {
            console.error("Failed to fetch products:", error);
            alert("无法加载产品数据，请稍后再试。");
        }
    };

  // Validate and update the cart based on available products (using title)
validateCart = (products) => {
    let tempCart = [...this.state.cart];
    const productTitles = products.map((product) => product.title);

    // Filter out cart items that no longer exist in the fetched products
    tempCart = tempCart.filter((item) => productTitles.includes(item.title));

    // Update cart and totals
    this.setState({ cart: tempCart }, this.addTotals);
};
    
    
    getItem = id => {
        const product = this.state.products.find(item => item.id === id);
        return product;
    };

    handleDetail = id => {
        const product = this.getItem(id);
        this.setState(() => {
            return { detailProduct: product };
        });
    };

    addToCart = id => {
        let tempProducts = [...this.state.products];
        const index = tempProducts.indexOf(this.getItem(id));
        const product = tempProducts[index];
        product.inCart = true;
        product.count = 1;
        const price = product.price;
        product.total = price;

        this.setState(() => {
            return {
                products: [...tempProducts],
                cart: [...this.state.cart, product],
                detailProduct: { ...product }
            };
        }, this.addTotals);
    };

    openModal = id => {
        const product = this.getItem(id);
        this.setState(() => {
            return { modalProduct: product, modalOpen: true };
        });
    };

    closeModal = () => {
        this.setState(() => {
            return { modalOpen: false };
        });
    };

    increment = (id) => {
        let tempCart = [...this.state.cart];
        const tempProducts = [...this.state.products];
        let selectedProduct = tempCart.find((item) => item.id === id);
    
        if (!selectedProduct) {
            // Product is not in the cart, find it in products and add to cart
            selectedProduct = tempProducts.find((item) => item.id === id);
    
            if (!selectedProduct) {
                console.error(`Product with id ${id} not found in products or cart`);
                return;
            }
    
            // Add the product to the cart for the first time
            selectedProduct.count = 1;
            selectedProduct.total = selectedProduct.price;
            tempCart = [...tempCart, selectedProduct];
        } else if (selectedProduct.count < selectedProduct.stock) {
            // Increment the product count if it's within the stock limit
            selectedProduct.count += 1;
            selectedProduct.total = selectedProduct.count * selectedProduct.price;
        } else {
            console.warn(`Cannot add more, stock limit reached for product ID: ${id}`);
            return;
        }
    
        this.setState(
            () => ({
                cart: tempCart,
            }),
            this.addTotals
        );
    };
    
    
    

    decrement = (id) => {
        let tempCart = [...this.state.cart];
        const selectedProduct = tempCart.find((item) => item.id === id);
        if (!selectedProduct) {
            console.error(`Product with id ${id} not found in cart`);
            return;
        }
    
        const index = tempCart.indexOf(selectedProduct);
        const product = tempCart[index];
    
        // Ensure count exists and initialize if necessary
        if (product.count === undefined) {
            product.count = 0;
        }
    
        product.count -= 1;
    
        if (product.count <= 0) {
            this.removeItem(id);
        } else {
            product.total = product.count * product.price;
            this.setState(
                () => ({
                    cart: [...tempCart],
                }),
                this.addTotals
            );
        }
    };
    

    getTotals = () => {
        let subTotal = 0;
        this.state.cart.map(item => (subTotal += item.total));
        const tempTax = subTotal * 0.1;
        const tax = parseFloat(tempTax.toFixed(2));
        const total = subTotal + tax;
        return {
            subTotal,
            tax,
            total
        };
    };

    addTotals = () => {
        const totals = this.getTotals();
        this.setState(
            () => {
                return {
                    cartSubTotal: totals.subTotal,
                    cartTax: totals.tax,
                    cartTotal: totals.total
                };
            }
        );
    };

    removeItem = id => {
        let tempProducts = [...this.state.products];
        let tempCart = [...this.state.cart];

        const index = tempProducts.indexOf(this.getItem(id));
        let removedProduct = tempProducts[index];
        removedProduct.inCart = false;
        removedProduct.count = 0;
        removedProduct.total = 0;

        tempCart = tempCart.filter(item => {
            return item.id !== id;
        });

        this.setState(() => {
            return {
                cart: [...tempCart],
                products: [...tempProducts]
            };
        }, this.addTotals);
    };

    clearCart = () => {
        this.setState(
            () => {
                return { cart: [] };
            },
            () => {
                this.setProducts(); // Reset products to initial state
                this.addTotals(); // Recalculate totals
                localStorage.removeItem("cart"); // Clear cart from localStorage
            }
        );
    };
    

    render() {
        return (
            <ProductContext.Provider
                value={{
                    ...this.state,
                    handleDetail: this.handleDetail,
                    addToCart: this.addToCart,
                    openModal: this.openModal,
                    closeModal: this.closeModal,
                    increment: this.increment,
                    decrement: this.decrement,
                    removeItem: this.removeItem,
                    clearCart: this.clearCart,
                    filterProducts: this.filterProducts,
                    fetchProducts: this.setProducts
                }}
            >
                {this.props.children}
            </ProductContext.Provider>
        );
    }
}

const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
