import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background-color: #f7fafc; /* Matches general theme background */
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Shadow elevation effect */
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748; /* Neutral dark text color */
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #4a5568; /* Muted text color */
  margin-bottom: 2rem;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #b68e5b; /* Matches button theme */
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d4a200;
  }
`;

export default function EmptyCart() {
  return (
    <Container>
      <Title>您的購物車是空的</Title>
      <Subtitle>趕快去挑選您喜歡的商品吧！</Subtitle>
      <Button to="/">返回購物</Button>
    </Container>
  );
}
