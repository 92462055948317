import React, { useState } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import ProductList from "./components/ProductList";
import Details from "./components/Details";
import Cart from "./components/Cart";
import Default from "./components/Default";
import Modal from './components/Modal';
import { ThemeConsumer } from './components/context/ThemeContexts';
import { AgeVerificationProvider } from './context/AgeVerificationContext';
import BottomNavbar from './components/BottomNavBar';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import OrderSummary from './components/OrderSummary';


function App() {
  const location = useLocation(); // Get current location
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  const handleAdminLogin = () => {
    setIsAdminAuthenticated(true);
  };

  return (
    <AgeVerificationProvider>
      <ThemeConsumer>
        {({ theme }) => (
          <React.Fragment>
            <div className={theme ? 'h-fit bg-slate-900' : 'h-fit'}>
              <Navbar />
              <Switch>
                <Route exact path="/" component={ProductList} />
                <Route path="/details" component={Details} />
                <Route path="/cart" component={Cart} />
                <Route path="/order-summary" component={OrderSummary} />
                <Route
                  path="/admin"
                  render={() =>
                    isAdminAuthenticated ? (
                      <AdminDashboard />
                    ) : (
                      <AdminLogin onLogin={handleAdminLogin} />
                    )
                  }
                /> {/* New route */}
                <Route component={Default} />
              </Switch>
              <Modal />
              {/* Conditionally render BottomNavbar only on the root page */}
              {location.pathname === "/" && <BottomNavbar />}
            </div>
          </React.Fragment>
        )}
      </ThemeConsumer>
    </AgeVerificationProvider>
  );
}

export default App;
