import React from "react";
import StockManagement from "./StockManagement";

const AdminDashboard = () => {
  return (
    <div style={{ padding: "20px" }}>
      {/* Title */}
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>管理库存</h1>
      
      {/* Render Stock Management */}
      <StockManagement />
    </div>
  );
};

export default AdminDashboard;
