import React from "react";
import styled from "styled-components";

const PurchaseSteps = () => {
  return (
    <StepsWrapper>
      <div className="step">
        <div className="box">選購商品，添加至購物車</div>
        <div className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="arrow-icon"
          >
            <path d="M12 22L6 12H10V2H14V12H18L12 22Z" />

          </svg>
        </div>
      </div>
      <div className="step">
        <div className="box">生成電子訂單後截圖或保存並發送至微信客服</div>
        <div className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="arrow-icon"
          >
           <path d="M12 22L6 12H10V2H14V12H18L12 22Z" />

          </svg>
        </div>
      </div>
      <div className="step">
        <div className="box">客服協助您完成付款及交收事宜</div>
        <div style={{height: '10rem'}}></div>
      </div>
    </StepsWrapper>
  );
};

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    position: relative;

    .box {
      background-color: #f9d537;
      padding: 15px 30px;
      font-size: 1rem;
      font-weight: bold;
      color: #333;
      border: 2px solid #ddd;
      border-color: #b68e5b
      border-radius: 8px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      text-align: left;
      width: 15rem;
    }

    .arrow {
      margin: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .arrow-icon {
        width: 24px;
        height: 24px;
        fill: #333; /* Filled arrow color */
        transition: fill 0.3s ease;

        &:hover {
          fill: #555; /* Slightly darker color on hover */
        }
      }
    }
  }
`;

export default PurchaseSteps;
