import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";

const SummaryCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Details = styled.div`
  flex: 1;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.25rem;
`;

const EnglishTitle = styled.p`
  font-size: 0.75rem;
  color: #000;
  margin-bottom: 0.25rem;
`;

const Year = styled.p`
  font-size: 0.75rem;
  color: #000;
  margin-bottom: 0.25rem;
`;

const Price = styled.p`
  font-size: 0.75rem;
  color: #000;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .control-button {
    width: 20px; /* Smaller size */
    height: 20px;
    border-radius: 50%;
    font-size: 1rem; /* Smaller font size */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &.decrement {
      background-color: transparent;
      border: 1px solid #b68e5b; /* Thinner border */
      color: #b68e5b;
    }

    &.increment {
      background-color: #b68e5b;
      border: none;
      padding-bottom: 2px;
      color: #fff;
    }
  }
  .control-button.increment:disabled {
    background-color: #e0e0e0; /* Gray background for disabled state */
    color: #a0a0a0; /* Light gray text color */
    cursor: not-allowed; /* Show a not-allowed cursor */
    border: 1px solid #d0d0d0; /* Light gray border */
  }

  .quantity {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    min-width: 20px;
    text-align: center;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #979393;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  border-top: 2px solid #e2e8f0;
  background-color: #fff;

  .total-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    .total-title {
      font-size: 0.8rem;
      font-weight: normal;
      color: #000;
    }

    .total-price {
      font-size: 0.8rem;
      font-weight: bold;
      color: #000;
    }
  }

  .order-button {
    background-color: #b48f5b;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #b68e5b;
    }
    &:disabled {
      background-color: #ddd;
      color: #888;
      cursor: not-allowed;
    }
  }
`;

const OrderButton = styled.button`
  background-color: #f9d537;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: #d4a200;
  }
`;

export default function ProductSummaryWithTotal({
  allProductsInCart,
  increment,
  decrement,
  removeItem,
}) {
  const history = useHistory();
  const cartTotal = allProductsInCart.reduce(
    (total, product) => total + product.price * product.count,
    0
  );
  const totalItems = allProductsInCart.reduce(
    (count, product) => count + product.count,
    0
  );

  const handleOrder = () => {
    history.push("/order-summary");
  };

  return (
    <>
      {allProductsInCart.map((product) => (
        <SummaryCard key={product.id}>
          <Image src={product.img} alt={product.title} />
          <Details>
            <Title>{product.title}</Title>
            <EnglishTitle>
              <strong>{product.englishTitle}</strong>
            </EnglishTitle>
            <Year>
              <strong>{product.year}</strong>
            </Year>
            <Price>
              <Price>
                <strong>HKD ${Number(product.price || 0)}</strong>
              </Price>
            </Price>
            <QuantityControls>
              <button
                className="control-button decrement"
                onClick={() => decrement(product.id)}
                disabled={product.count === 0}
              >
                −
              </button>
              <span className="quantity">{product.count}</span>
              <button
                className="control-button increment"
                onClick={() => increment(product.id)}
                disabled={product.count >= product.stock}
              >
                +
              </button>
            </QuantityControls>
          </Details>
          <RemoveButton onClick={() => removeItem(product.id)}>
            <i className="fas fa-trash"></i>
          </RemoveButton>
        </SummaryCard>
      ))}
      {/* Render TotalRow only once */}
      <TotalRow>
        <div className="total-details">
          <span className="total-title">
            總計: <strong>{totalItems}件</strong>
          </span>
          <span className="total-price">HKD ${cartTotal.toFixed(2)}</span>
        </div>
        <button
          disabled={totalItems === 0}
          onClick={handleOrder}
          className="order-button"
        >
          生成訂單
        </button>
      </TotalRow>
    </>
  );
}
