import styled from 'styled-components';

export const ButtonContainer = styled.button`
  text-transform: capitalize;
  font-size: 1rem;
  background: ${(props) => (props.cart ? "var(--lightBlue)" : "transparent")};
  border: ${(props) => (props.cart ? "none" : "0.05rem solid var(--lightBlue)")};
  color: ${(props) => (props.cart ? "var(--mainWhite)" : "var(--lightBlue)")};
  border-radius: 0.5rem;
  padding: 0.4rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${(props) => (props.cart ? "var(--mainBlue)" : "var(--lightBlue)")};
    color: var(--mainWhite);
    border: ${(props) => (props.cart ? "none" : "0.05rem solid var(--mainBlue)")};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: var(--mainDark);
    color: var(--mainWhite);
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
