import React, { useState, useEffect } from "react";
import ProductTable from "./ProductTable";
import EditProductForm from "./EditProductForm";
import AddProductForm from "./AddProductForm";

const StockManagement = () => {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const API_URL = "https://api.garturbo.com"; // Or replace with HTTPS if applicable

  // Centralized function to fetch products
  const fetchStockData = async () => {
    try {
      const response = await fetch(`${API_URL}/stock/getStock`);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const rawData = await response.json();
      if (
        rawData.status === "success" &&
        rawData.data &&
        rawData.data.stocks
      ) {
        const stocks = rawData.data.stocks.map((product) => ({
          ...product,
          img: product.img,
        }));
        setProducts(stocks);
      } else {
        throw new Error("Unexpected API response structure");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert("无法加载库存数据，请稍后再试。");
    }
  };

  // Fetch products on component mount
  useEffect(() => {
    fetchStockData();
  }, []);

  const handleEdit = (product) => {
    setEditingProduct(product);
    setIsAdding(false);
  };

  const handleRemove = async (id) => {
    const confirmRemove = window.confirm("确定要删除这个产品吗？");
    if (confirmRemove) {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("adminToken");
        if (!token) {
          throw new Error("未找到令牌。请先登录。");
        }
  
        const response = await fetch(`${API_URL}/stock/deleteStock/${id}`, {
          method: "DELETE",
          headers: {
            token: `${token}`, // Include the token in the header
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error deleting product: ${response.statusText}`);
        }
  
        const result = await response.json();
        // console.log("Delete Result:", result);
  
        if (result.status === "success") {
          // Re-fetch products after successful deletion
          fetchStockData();
          alert("产品已删除！");
        } else {
          throw new Error("Unexpected API response structure");
        }
      } catch (error) {
        console.error("Error occurred while deleting product:", error);
        alert("无法删除产品，请稍后再试。");
      }
    }
  };
  

  const handleSaveEdit = async (updatedProduct) => {
    try {
      const formData = new FormData();
  
      // Append all product data to FormData
      for (const key in updatedProduct) {
        if (key === "image" && updatedProduct[key]) {
          formData.append(key, updatedProduct[key]); // Append the file
        } else if (updatedProduct[key]) {
          formData.append(key, updatedProduct[key]); // Append other fields
        }
      }
  
      // Retrieve the token from localStorage
      const token = localStorage.getItem("adminToken");
      if (!token) {
        throw new Error("未找到令牌。请先登录。");
      }
  
      const response = await fetch(`${API_URL}/stock/updateStock`, {
        method: "POST",
        headers: {
          token: `${token}`, // Include the token in the header
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Error updating stock: ${response.statusText}`);
      }
  
      const result = await response.json();
      // console.log("Update Result:", result);
  
      if (result.status === "success") {
        // Re-fetch products after successful update
        fetchStockData();
        alert("产品信息已成功更新！");
      } else {
        throw new Error("Unexpected API response structure");
      }
    } catch (error) {
      console.error("Error occurred while updating stock:", error);
      alert("无法更新产品信息，请稍后再试。");
    } finally {
      setEditingProduct(null); // Exit editing mode
    }
  };
  

  const handleAddProduct = async (newProduct) => {
    try {
      const formData = new FormData();
  
      // Append all product data to FormData
      for (const key in newProduct) {
        if (key === "image" && newProduct[key]) {
          formData.append(key, newProduct[key]); // Append the file
        } else if (newProduct[key]) {
          formData.append(key, newProduct[key]); // Append other fields
        }
      }
  
      // Retrieve the token from localStorage
      const token = localStorage.getItem("adminToken");
      if (!token) {
        throw new Error("未找到令牌。请先登录。");
      }
  
      const response = await fetch(`${API_URL}/stock/insertStock`, {
        method: "POST",
        headers: {
          token: `${token}`, // Include the token in the header
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Error adding product: ${response.statusText}`);
      }
  
      const result = await response.json();
      // console.log("Insert Result:", result);
  
      if (result.status === "success") {
        // Re-fetch products after successful insertion
        fetchStockData();
        alert("新产品已成功添加！");
      } else {
        throw new Error("Unexpected API response structure");
      }
    } catch (error) {
      console.error("Error occurred while adding product:", error);
      alert("无法添加新产品，请稍后再试。");
    }
  };
  

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {editingProduct ? (
        <EditProductForm
          product={editingProduct}
          onSave={handleSaveEdit}
          onCancel={() => setEditingProduct(null)}
        />
      ) : isAdding ? (
        <AddProductForm
          onSave={handleAddProduct}
          onCancel={() => setIsAdding(false)}
        />
      ) : (
        <ProductTable
          products={products}
          onEdit={handleEdit}
          onRemove={handleRemove}
          onAdd={() => setIsAdding(true)}
        />
      )}
    </div>
  );
};

export default StockManagement;
