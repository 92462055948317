import React, { Component } from "react";
import styled from "styled-components";
import EmptyCart from "./EmptyCart";
import { ProductConsumer } from "../../context";
import ProductSummaryWithTotal from "./ProductSummary";
import BottomInfoSection from "../BottomInfoSection";

const Section = styled.section`
  background-color: #fff;
  padding: 2rem 0;
`;

const Container = styled.div`
  max-width: 100%; /* Adjusted for better responsiveness */
  margin: 0 auto;
  padding: 0 1rem;
`;

const Title = styled.h1`
  font-size: 1.2rem; /* Responsive text size */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #2d3748; /* Gray color */
  border-bottom: 0.125rem solid #e2e8f0; /* Subtle gray underline */
  padding-bottom: 0.5rem;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
`;

const InfoSection = styled.div`
  padding-bottom: 5rem; /* Relative padding */
  padding-left: 1rem;

  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
    gap: 1rem; /* Adjust spacing between items */
  }

  .logo {
    width: 30%; /* Responsive width */
    max-width: 150px; /* Limit size for larger screens */
    height: auto;
  }

  .qr-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center aligns text and QR code horizontally */
  }

  .qr-text {
    font-size: 0.6rem;
    font-weight: bold;
    color: #333;
    margin-top: 0.5rem;
  }

  .qr-code {
    width: 6rem; /* Responsive QR code size */
  }

  ul {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1.8;
    font-size: 0.8rem;
    color: #333;

    li {
      margin-bottom: 0.5rem;
      strong {
        color: #000;
      }
    }
  }
`;

export default class Store extends Component {
  render() {
    return (
      <Section>
        <ProductConsumer>
          {(value) => {
            const { cart } = value;
            if (cart.length > 0) {
              return (
                <React.Fragment>
                  <Container>
                    {/* Title styled with styled-components */}
                    <Title>購物車</Title>
                    <Card>
                      <ProductSummaryWithTotal
                        allProductsInCart={cart}
                        increment={value.increment}
                        decrement={value.decrement}
                        removeItem={value.removeItem}
                      />
                    </Card>
                    {/* Info Section */}
                    <hr
                      style={{
                        marginTop: "10rem",
                        border: "none",
                        borderTop: "0.125rem solid #ddd", // Adjusted for consistency
                      }}
                    />
                    <BottomInfoSection/>
                  </Container>
                </React.Fragment>
              );
            } else {
              return (
                <Container className="flex items-center justify-center">
                  <EmptyCart />
                </Container>
              );
            }
          }}
        </ProductConsumer>
      </Section>
    );
  }
}
