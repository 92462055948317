import React, { useEffect, useState } from "react";

const AdminLogin = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true); // For showing a loading state during auto-login
  const [error, setError] = useState("");
  const API_URL = "https://api.garturbo.com"; // Or replace with HTTPS if applicable

  // Check for token and auto-login
  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      (async () => {
        try {
          const response = await fetch(`${API_URL}/auth/authenticateToken`, {
            method: "GET",
            headers: {
              token: `${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            onLogin(); // Call parent function to set auth state
          } else {
            throw new Error("Token validation failed");
          }
        } catch (err) {
          console.error("Auto-login error:", err.message);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false); // No token available, proceed to login form
    }
  }, [onLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const data = await response.json();
      const { token } = data.data;

      // Save token to localStorage
      localStorage.setItem("adminToken", token);
      onLogin(); // Call parent function to set auth state
    } catch (err) {
      setError(err.message);
      console.error("Login error:", err.message);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Show a loading state while checking the token
  }

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>管理员登录</h2>
      
      {error && <p style={{ color: "red" }}>{error}</p>}
      
      <div style={{ marginBottom: "15px" }}>
        <label htmlFor="username" style={{ display: "block", marginBottom: "5px" }}>用户名:</label>
        <input
          id="username"
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ width: "100%", padding: "10px", fontSize: "16px", borderRadius: "4px", border: "1px solid #ccc" }}
        />
      </div>
      
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="password" style={{ display: "block", marginBottom: "5px" }}>密码:</label>
        <input
          id="password"
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: "100%", padding: "10px", fontSize: "16px", borderRadius: "4px", border: "1px solid #ccc" }}
        />
      </div>
      
      <button
        type="submit"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "18px",
          borderRadius: "4px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        登录
      </button>
    </form>
  );
};

export default AdminLogin;
