import React, { useState } from "react";
import Select from "react-select";

const categoryOptions = [
  { value: "整盒購買", label: "整盒购买" },
  { value: "限時優惠", label: "限时优惠" },
  { value: "陳年老茄", label: "陈年老茄" },
];
const API_URL = "https://api.garturbo.com"; 

const EditProductForm = ({ product, onSave, onCancel }) => {
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const [previewImage, setPreviewImage] = useState(
    `${API_URL}/images/${product.img}`,
  );


  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct({ ...editedProduct, [name]: value });
  };

  const handleCategoryChange = (selectedOption) => {
    setEditedProduct({ ...editedProduct, category: selectedOption.value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        const uniqueFileName = `${Date.now()}_${file.name}`; // Unique filename using timestamp

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);

        setEditedProduct({ 
            ...editedProduct, 
            img: uniqueFileName, // Unique filename for backend reference
            image: file, // Actual file object for upload
        });
    }
};

  
  return (
    <div
      style={{ padding: "20px", border: "1px solid #ddd", borderRadius: "5px" }}
    >
      <h3 style={{ textAlign: "center" }}>编辑产品</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave(editedProduct);
        }}
      >
        {["title", "englishTitle", "brand", "year", "price", "stock"].map(
          (field) => (
            <div key={field} style={{ marginBottom: "15px" }}>
              <label>
                {field === "title"
                  ? "名称"
                  : field === "englishTitle"
                  ? "英文名"
                  : field === "year"
                  ? "年份"
                  : field === "price"
                  ? "价格"
                  : field === "brand"
                  ? "品牌"
                  : "库存"}
                :
              </label>
              <input
                type={
                  field === "price" || field === "stock" ? "number" : "text"
                }
                name={field}
                value={editedProduct[field]}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
          )
        )}

        {/* Category Dropdown using react-select */}
        <div style={{ marginBottom: "15px" }}>
          <label>类别:</label>
          <Select
            options={categoryOptions}
            placeholder={editedProduct.category}
            isSearchable={false}
            value={categoryOptions.find(
              (option) => option.value === editedProduct.category
            )}
            onChange={handleCategoryChange}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: "#ccc",
                borderRadius: "4px",
                padding: "4px",
              }),
            }}
          />
        </div>

        {/* Image Upload */}
        <div style={{ marginBottom: "15px" }}>
          <label>图片:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "block", marginBottom: "10px" }}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          )}
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            style={{
              marginRight: "10px",
              padding: "5px 10px",
              fontSize: "14px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            取消
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProductForm;
