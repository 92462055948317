import React, { createContext, useState, useEffect } from "react";

// Create Context
export const AgeVerificationContext = createContext();

export const AgeVerificationProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);

  // Load verification status from localStorage on mount
  useEffect(() => {
    const storedVerification = localStorage.getItem("isVerified");
    if (storedVerification === "true") {
      setIsVerified(true);
    }
  }, []);

  // Function to handle verification
  const verifyAge = () => {
    setIsVerified(true);
    localStorage.setItem("isVerified", "true"); // Persist verification
  };

  return (
    <AgeVerificationContext.Provider value={{ isVerified, verifyAge }}>
      {children}
    </AgeVerificationContext.Provider>
  );
};
