export const storeProducts = [
  {
    id: 1,
    title: "高希霸世纪6号",
    englishTitle: 'Cohiba',
    year: '2004',
    category: '整盒購買',
    brand: '高希霸',
    img: "img/product-1.png",
    price: 35000,
    company: "Cohiba",
    info:
      "高希霸世纪六号规格独特，只出现在这个哈瓦那品牌中。强壮和精致外观，这世纪六号散发出奶油，皮革，可可和甜香料的味道。",
    inCart: false,
    count: 0,
    total: 10,
  },
  {
    id: 2,
    title: "Montecristo No. 2",
    englishTitle: "Montecristo",
    category: '限時優惠',
    year: '2004',
    brand: '蒙特',
    img: "img/product-2.png",
    price: 2800,
    company: "Montecristo",
    info:
      "Known for its iconic torpedo shape, the Montecristo No. 2 delivers a smooth, medium-bodied smoke with flavors of cocoa, coffee, and wood. A classic choice for cigar aficionados.",
    inCart: false,
    count: 0,
    total: 10,
  },
  {
    id: 3,
    title: "Romeo y Julieta Churchill",
    englishTitle: "Romeo y Julieta",
    category: '限時優惠',
    year: '2004',
    brand: '罗密欧与朱丽叶',
    img: "img/product-3.png",
    price: 3000,
    company: "Romeo y Julieta",
    info:
      "A tribute to Winston Churchill, this cigar is known for its rich flavor profile, featuring notes of nuts, spices, and cedar. A timeless Cuban classic with a robust finish.",
    inCart: false,
    count: 0,
    total: 20,
  },
  {
    id: 4,
    title: "Partagás Serie D No. 4",
    englishTitle: "Partagás",
    year: '2004',
    category: '限時優惠',
    brand: '帕得加斯',
    img: "img/product-4.png",
    price: 2500,
    company: "Partagás",
    info:
      "This full-bodied cigar delivers a complex flavor with hints of spice, earth, and dark chocolate. Handcrafted in Cuba, it is a favorite among seasoned smokers.",
    inCart: false,
    count: 0,
    total: 50,
  },
  {
    id: 5,
    title: "大卫杜夫周年纪念3号",
    englishTitle: "Davidoff aniversario no. 3",
    year: '2004',
    category: '整盒購買',
    brand: '大卫杜夫',
    img: "img/product-5.png",
    price: 4500,
    company: "Davidoff",
    info:
      "The Davidoff Aniversario No. 3 is an elegant cigar offering a mild-to-medium smoke with notes of cream, almonds, and a touch of pepper. Perfect for a refined smoking experience.",
    inCart: false,
    count: 0,
    total: 30,
  },
  {
    id: 6,
    title: "Arturo Fuente Opus X",
    englishTitle: "Arturo Fuente",
    year: '2004',
    category: '整盒購買',
    brand: '阿图罗-富恩特',
    img: "img/product-6.png",
    price: 500,
    company: "Arturo Fuente",
    info:
      "A legendary cigar with a bold, full-bodied profile. The Opus X features rich flavors of spice, leather, and cedar, making it a must-try for any cigar enthusiast.",
    inCart: false,
    count: 0,
    total: 20,
  },
  {
    id: 7,
    title: "泰山巅峰6号",
    englishTitle: "Taishan",
    year: '2004',
    category: '限時優惠',
    brand: '泰山',
    img: "img/product-7.png",
    price: 500,
    company: "泰山",
    info:
      "口味特点是香气纯正、温和，劲头适中，余味舒适且美妙，烟入口顺滑，余味甘爽回甜。曾被《雪茄专家》杂志评为中式雪茄榜首，是一款具有东方特色的优质雪茄烟。如果你喜欢一款口感醇和细腻，茄香纯正怡人，余味甘爽回甜的雪茄烟，你可以尝试一下泰山巅峰6号雪茄，或许你会喜欢它的味道和风格",
    inCart: false,
    count: 0,
    total: 20,
  },
  {
    id: 8,
    title: "泰山巅峰8号",
    englishTitle: "Taishan",
    year: '2004',
    category: '整盒購買',
    brand: '泰山',
    img: "img/product-7.png",
    price: 500,
    company: "泰山",
    info:
      "口味特点是香气纯正、温和，劲头适中，余味舒适且美妙，烟入口顺滑，余味甘爽回甜。曾被《雪茄专家》杂志评为中式雪茄榜首，是一款具有东方特色的优质雪茄烟。如果你喜欢一款口感醇和细腻，茄香纯正怡人，余味甘爽回甜的雪茄烟，你可以尝试一下泰山巅峰6号雪茄，或许你会喜欢它的味道和风格",
    inCart: false,
    count: 0,
    total: 10,
  },
  {
    id: 9,
    title: "泰山巅峰9号",
    englishTitle: "Taishan",
    year: '2004',
    category: '整盒購買',
    brand: '泰山',
    img: "img/product-7.png",
    price: 500,
    company: "泰山",
    info:
      "口味特点是香气纯正、温和，劲头适中，余味舒适且美妙，烟入口顺滑，余味甘爽回甜。曾被《雪茄专家》杂志评为中式雪茄榜首，是一款具有东方特色的优质雪茄烟。如果你喜欢一款口感醇和细腻，茄香纯正怡人，余味甘爽回甜的雪茄烟，你可以尝试一下泰山巅峰6号雪茄，或许你会喜欢它的味道和风格",
    inCart: false,
    count: 0,
    total: 15,
  },
  {
    id: 10,
    title: "泰山巅峰10号",
    englishTitle: "Taishan",
    year: '2004',
    category: '整盒購買',
    brand: '泰山',
    img: "img/product-7.png",
    price: 500,
    company: "泰山",
    info:
      "口味特点是香气纯正、温和，劲头适中，余味舒适且美妙，烟入口顺滑，余味甘爽回甜。曾被《雪茄专家》杂志评为中式雪茄榜首，是一款具有东方特色的优质雪茄烟。如果你喜欢一款口感醇和细腻，茄香纯正怡人，余味甘爽回甜的雪茄烟，你可以尝试一下泰山巅峰6号雪茄，或许你会喜欢它的味道和风格",
    inCart: false,
    count: 0,
    total: 19,
  },
];

export const detailProduct = {
  id: 1,
  title: "Cohiba Siglo VI",
  img: "img/product-1.png",
  price: 35,
  company: "Cohiba",
  info:
    "The Cohiba Siglo VI is one of the most sought-after Cuban cigars, offering a rich, creamy smoke with notes of cedar, leather, and a hint of sweetness. Hand-rolled in Cuba, this cigar is perfect for a special occasion.",
  inCart: false,
  count: 0,
  total: 0,
};
