import React from "react";
import { FaTrash } from "react-icons/fa";

const ProductTable = ({ products, onEdit, onRemove, onAdd }) => {
  return (
    <>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "4px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#f4f4f4", textAlign: "left" }}>
            <th
              style={{
                width: "45%",
                padding: "8px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              名称
            </th>
            <th
              style={{
                width: "20%",
                padding: "8px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              库存
            </th>
            <th
              style={{
                width: "50%",
                padding: "8px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              操作
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td
                style={{
                  padding: "8px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {product.title}
              </td>
              <td
                style={{
                  padding: "8px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {product.stock || "未定义"}
              </td>
              <td
                style={{
                  padding: "8px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                <button
                  onClick={() => onEdit(product)}
                  style={{
                    marginRight: "8px",
                    padding: "4px 8px",
                    fontSize: "12px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "3px",
                    cursor: "pointer",
                  }}
                >
                  编辑
                </button>
                <button
                  onClick={() => onRemove(product.id)}
                  style={{
                    padding: "4px 8px",
                    fontSize: "12px",
                    backgroundColor: "transparent",
                    color: "#dc3545",
                    border: "none",
                    cursor: "pointer",
                  }}
                  title="删除"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={onAdd}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          添加产品
        </button>
      </div>
    </>
  );
};

export default ProductTable;
