import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ProductConsumer } from "../context";
import { ThemeConsumer } from "./context/ThemeContexts";

export default class Product extends React.Component {
  render() {
    const { id, title, englishTitle, year, img, price } = this.props.product;

    return (
      <ThemeConsumer>
        {({ theme }) => (
          <ProductWrapper
            className={`product-item ${theme ? "dark-theme" : ""}`}
            style={{ width: "100%" }}
          >
            <div className="product-container">
              {/* Left Section: Image */}
              <div className="product-image">
                <img src={img} alt={title} />
              </div>

              {/* Right Section: Details */}
              <div className="product-details">
                <h3 className="product-title">{title}</h3>
                <p className="product-english-name">{englishTitle}</p>
                <p className="product-year">{year}</p>
                <div className="product-price-row">
                  <span className="product-price">HKD ${price}</span>
                  <ProductConsumer>
                    {(value) => {
                      const productInCart = value.cart.find(
                        (item) => item.id === id
                      );
                      const quantity = productInCart ? productInCart.count : 0;
                      const { stock } = this.props.product;

                      return (
                        <div className="quantity-controls">
                          <button
                            className="control-button decrement"
                            onClick={() => value.decrement(id)}
                            disabled={quantity === 0}
                          >
                            −
                          </button>
                          <span className="quantity">{quantity}</span>
                          <button
                            className="control-button increment"
                            onClick={() => value.increment(id)}
                            disabled={quantity >= stock}
                          >
                            +
                          </button>
                        </div>
                      );
                    }}
                  </ProductConsumer>
                </div>
              </div>
            </div>
          </ProductWrapper>
        )}
      </ThemeConsumer>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    englishTitle: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

const ProductWrapper = styled.div`
  .product-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    max-width: 91%; /* Constrain the width of the product card */
    margin-left: 0.3rem;
    transition: box-shadow 0.3s ease-in-out;
  }

  .product-container:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .product-image {
    flex: 0 0 6rem; /* Set fixed width for the image container */
    margin-right: 0.5rem;
  }

  .product-image img {
    width: 100%; /* Stretch to container width */
    height: 6rem; /* Set consistent height */
    border-radius: 5%; /* Rounded corners */
    object-fit: crop; /* Crop image to maintain aspect ratio */
  }

  .product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .product-title {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0 0 0.2rem;
    color: #333;
  }

  .product-english-name {
    font-size: 0.6rem;
    color: #555;
    margin: 0 0 0.1rem;
  }

  .product-year {
    font-size: 0.6rem;
    color: #777;
    margin: 0 0 0.2rem;
  }

  .product-price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-price {
    font-size: 0.6rem;
    font-weight: bold;
    color: #6b6b6b;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem; /* Adjust gap for smaller buttons */
  }

  .control-button {
    width: 20px; /* Smaller size */
    height: 20px;
    border-radius: 50%;
    font-size: 1rem; /* Smaller font size */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &.decrement {
      background-color: transparent;
      border: 1px solid #b68e5b; /* Thinner border */
      color: #b68e5b;
    }

    &.increment {
      background-color: #b68e5b;
      border: none;
      padding-bottom: 2px;
      color: #fff;
    }
  }

  .control-button.increment:disabled {
    background-color: #e0e0e0; /* Gray background for disabled state */
    color: #a0a0a0; /* Light gray text color */
    cursor: not-allowed; /* Show a not-allowed cursor */
    border: 1px solid #d0d0d0; /* Light gray border */
  }

  .quantity {
    font-size: 1rem; /* Size of the quantity number */
    font-weight: bold;
    color: #333;
    min-width: 20px; /* Ensure consistent spacing */
    text-align: center;
  }
`;
