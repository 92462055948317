import React, { Component, createRef } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { ButtonContainer } from "./Button";
import {
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineShopping,
} from "react-icons/ai";
import { ProductConsumer } from "../context"; // Use ProductConsumer for dynamic context
import { withRouter } from "react-router-dom/cjs/react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768,
      menuOpen: false,
      cartOpen: false,
    };

    this.menuRef = createRef();
    this.menuButtonRef = createRef();

    this.handleResize = this.handleResize.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleCartToggle = this.handleCartToggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleResize() {
    this.setState({
      isMobile: window.innerWidth <= 768,
    });
  }

  handleMenu() {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  handleOutsideClick(event) {
    if (
      this.menuRef.current &&
      this.menuButtonRef.current &&
      !this.menuRef.current.contains(event.target) &&
      !this.menuButtonRef.current.contains(event.target)
    ) {
      this.closeMenu();
    }
  }

  handleCartToggle() {
    this.setState(
      (prevState) => ({ cartOpen: !prevState.cartOpen }),
      () => {
        if (this.state.cartOpen) {
          // Navigate to cart only if not already on cart page
          if (this.props.location.pathname !== "/cart") {
            this.props.history.push("/cart");
          }
        } else {
          // Navigate back only if not already on the previous page
          this.props.history.push("/");
        }
      }
    );
  }

  render() {
    const { isMobile, menuOpen } = this.state;

    return (
      <ProductConsumer>
        {(value) => {
          const cartCount = value.cart.length; // Get cart count dynamically

          return (
            <NavWrapper>
              <div className="navbar-container">
                <div className="actions">
                  <button
                    ref={this.menuButtonRef} // Attach the ref to the menu button
                    className="menu-toggle"
                    onClick={this.handleMenu}
                  >
                    <AiOutlineSearch size={28} color="#000" />
                  </button>
                </div>
                <div className="logo-container">
                  <Link to="/">
                    <img
                      src={"img/garturbo-brand.jpeg"}
                      alt="product"
                      className="logo"
                      width={"30%"}
                      height={"auto"}
                    />
                  </Link>
                  <Link to="/">
                  <img
                    src={"img/brand-text-name.jpeg"} // Replace with your image path
                    alt="brand logo"
                    className="logo"
                    width="40%"
                    height="auto"
                  />
                     </Link>
                </div>

                <div>
                  <button className="cart-icon" onClick={this.handleCartToggle}>
                    <div className="cart-wrapper">
                      <AiOutlineShopping size={32} />
                      {cartCount > 0 && (
                        <span className="cart-badge">{cartCount}</span>
                      )}
                    </div>
                  </button>
                </div>
              </div>

              <MenuWrapper
                menuOpen={menuOpen}
                ref={this.menuRef} // Attach the ref to the menu wrapper
              >
                <MenuContent>
                  <SearchBar>
                    <ProductConsumer>
                      {(value) => (
                        <input
                          type="text"
                          placeholder="搜尋"
                          onChange={(e) => value.filterProducts(e.target.value)}
                        />
                      )}
                    </ProductConsumer>
                  </SearchBar>
                  {/* <NavLink
                    to="/"
                    exact
                    className="menu-item"
                    activeClassName="active"
                    onClick={this.closeMenu}
                  >
                    全部雪茄
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="menu-item about"
                    activeClassName="active"
                    onClick={this.closeMenu}
                  >
                    關於我們
                  </NavLink> */}
                </MenuContent>
              </MenuWrapper>
            </NavWrapper>
          );
        }}
      </ProductConsumer>
    );
  }
}

const MenuWrapper = styled.div`
  max-height: ${(props) => (props.menuOpen ? "300px" : "0")};
  overflow: hidden;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  padding: ${(props) => (props.menuOpen ? "1rem" : "0")};
  transition: max-height 0.3s ease, padding 0.3s ease;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SearchBar = styled.div`
  input {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 25px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, border-color 0.3s ease;

    &:focus {
      border-color: #b68e5b;
      box-shadow: 0 0 5px #b68e5b;
    }

    &::placeholder {
      color: #bbb;
    }
  }
`;

const BrandText = styled.div`
  font-family: "Poppins", sans-serif; /* Modern and clean font */
  font-size: 2rem; /* Larger font size for a prominent title */
  font-weight: 700; /* Stronger weight for boldness */
  color: #b68e5b; /* Updated to match the logo text color */
  text-align: center;
  text-transform: uppercase; /* Make the text uppercase for branding impact */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.3s ease; /* Smooth hover effect */
  margin-left: -1rem;

  &:hover {
    transform: scale(1.05); /* Slight enlargement on hover */
  }

  @media (max-width: 768px) {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
`;

const NavWrapper = styled.nav`
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  top: 0;
  z-index: 1000;

  .cart-icon {
    position: relative;
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem; /* Consistent padding on both sides */
    height: 100%;
    box-sizing: border-box; /* Include padding in width calculations */
  }

  .menu-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem; /* Consistent padding */
    margin-left: 0; /* Ensure no unintended left margin */
  }

  .logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    display: inline-block; /* Ensure no extra space */
  }

  .logo-container img:first-child {
    margin-right: 0.8rem; /* Add space to the right of the first image */
}

  .logo {
    max-width: 120px; /* Ensure logo scales correctly */
    object-fit: contain;
  }

  .cart-wrapper {
    position: relative; /* Set position to allow correct alignment for the badge */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart-badge {
    position: absolute;
    top: -10px; /* Adjust the vertical position to bring it closer */
    right: -10px; /* Adjust the horizontal position to align with the icon */
    background-color: #b68e5b;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for better visibility */
  }

  .menu-item {
    text-decoration: none;
    color: #919594;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  .menu-item.active {
    color: #b68e5b;
    font-weight: bold;
  }

  .menu-item.about {
    font-size: 1rem;
    font-weight: bold;
    color: #919594;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  .menu-item.about.active {
    color: #b68e5b;
  }
`;

export default withRouter(Navbar);
