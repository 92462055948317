import React, { useContext, useEffect, useState } from "react";
import Product from "./Product";
import { ProductConsumer } from "../context";
import { ThemeConsumer } from "./context/ThemeContexts";
import { AgeVerificationContext } from "../context/AgeVerificationContext";
import PurchaseSteps from "./PurchaseSteps";
import BottomInfoSection from "./BottomInfoSection";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function ProductList() {
  const { isVerified, verifyAge } = useContext(AgeVerificationContext);
  const [activeTab, setActiveTab] = useState(""); // Default active tab (empty for showing all)
  const [activeTabBar, setActiveTabBar] = useState("整盒購買"); // Default tab

  const location = useLocation(); // Tracks the current route location

  const { fetchProducts } = useContext(ProductConsumer);
  useEffect(() => {
    fetchProducts();
  }, [location, fetchProducts]);

  const filterProducts = (products) => {
    if (activeTabBar && activeTabBar !== "整盒購買") {
      // Only filter by activeTabBar if it's not "整盒購買"
      return products.filter((product) => product.category === activeTabBar);
    }
    if (activeTab && activeTabBar) {
      // Apply both filters when activeTabBar is "整盒購買"
      return products.filter(
        (product) =>
          product.brand === activeTab && product.category === activeTabBar
      );
    }
    if (activeTab) {
      // Apply only activeTab filter
      return products.filter((product) => product.brand === activeTab);
    }
    if (activeTabBar) {
      // Apply only activeTabBar filter
      return products.filter((product) => product.category === activeTabBar);
    }
    return products; // No filters applied
  };

  return (
    <ThemeConsumer>
      {({ theme }) => (
        <ProductConsumer>
          {(value) => {
            const brands = [
              ...new Set(value.products.map((product) => product.brand)),
            ];
            const filteredProducts = filterProducts(value.products);

            return (
              <div className={theme ? "bg-slate-100" : "bg-blue-50"}>
                {/* Age Verification Modal */}
                {!isVerified && (
                  <div className="modal-background">
                    <div className="modal-content">
                      <h1 className="brand-name text-xl font-extrabold mb-4 text-blue-400">
                        Garturbo
                      </h1>
                      <h2>請問您是否已滿18歲</h2>
                      <button
                        onClick={verifyAge}
                        className="btn btn-primary"
                        style={{ color: "black" }} // Inline style for black text
                      >
                        確認
                      </button>
                      <p style={{ marginTop: 20 }}>
                        <strong>[嚴正聲明]</strong>
                      </p>
                      <p>
                        • 客戶必須依所在國家或地區的海關要求如實報關及繳付稅款
                      </p>
                      <p>
                        •
                        為維護客戶於茄多堡Garturbo的購買安全與合法權益，客戶在瀏覽網站及購買前，應先了解網站下方列明的使用條款
                      </p>
                      <hr
                        style={{
                          border: "none",
                          borderTop: "1px solid #5A5A5A", // Light gray line
                          width: "100%",
                          margin: "20px 0", // Vertical spacing
                        }}
                      />
                      <p style={{ marginTop: 20 }}>
                        <strong>忠告：吸煙危害健康</strong>
                      </p>
                      <p>• 依香港煙草法例規定，本網站僅年滿18歲瀏覽</p>
                      <p>
                        •
                        所有交易於香港特別行政區進行，服務條款及程序均受香港特別行政區法律管轄
                      </p>
                    </div>
                  </div>
                )}

                {/* Normal Tab Bar */}
                {isVerified && (
                  <div className="normal-tab-bar">
                    {["整盒購買", "限時優惠", "陳年老茄", "購買流程"].map(
                      (tab) => (
                        <button
                          key={tab}
                          className={`tab-button ${
                            activeTabBar === tab ? "active" : ""
                          }`}
                          onClick={() => setActiveTabBar(tab)}
                        >
                          {tab}
                        </button>
                      )
                    )}
                  </div>
                )}
                {activeTabBar === "購買流程" ? (
                  <PurchaseSteps />
                ) : (
                  <div className="main-layout">
                    {/* Vertical Tabs */}
                    {activeTabBar === "整盒購買" && (
                      <div className="vertical-tabs">
                        <button
                          className={`vertical-tab ${
                            activeTab === "" ? "active" : ""
                          }`}
                          style={{
                            color: theme ? "#FFFFFF" : "#000000",
                          }}
                          onClick={() => setActiveTab("")}
                        >
                          全部
                        </button>
                        {brands
                          .filter((brand) =>
                            value.products.some(
                              (product) =>
                                product.brand === brand &&
                                product.category === "整盒購買"
                            )
                          )
                          .map((brand) => (
                            <button
                              key={brand}
                              className={`vertical-tab ${
                                activeTab === brand ? "active" : ""
                              }`}
                              style={{
                                color: theme ? "#FFFFFF" : "#000000",
                              }}
                              onClick={() => setActiveTab(brand)}
                            >
                              {brand}
                            </button>
                          ))}
                      </div>
                    )}

                    {/* Product List */}
                    <div className="product-list">
                      {filteredProducts.length > 0 ? (
                        <div className="row">
                          {filteredProducts.map((product) => (
                            <Product key={product.id} product={product} />
                          ))}
                        </div>
                      ) : (
                        <div className="no-results">
                          <p>抱歉，没有相关产品</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <hr
                  style={{
                    border: "none",
                    marginTop: activeTabBar === '購買流程' ? '0rem' : '10rem',
                    borderTop: "2px solid #ddd", // Adjust the color to match your theme
                    marginLeft: "12px",
                    marginRight: "12px", // Space between the product content and the divider
                  }}
                />
                {/* Info Section */}
                <div
                  className="info-section"
                  style={{
                    paddingBottom: "5rem", // Relative padding
                  }}
                >
                  <BottomInfoSection />
                </div>
              </div>
            );
          }}
        </ProductConsumer>
      )}
    </ThemeConsumer>
  );
}

export default ProductList;
